<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
          <router-link :to="{ name: 'adminRoute-coupon-list' }">
            <font-awesome-icon
              class="mr-3"
              :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
          </router-link>
          <span>クーポンを新規追加</span>
        </h4>
      </div>
    </div>
    <form v-on:submit="validateCreateCoupon">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">クーポン名</p>
                <input
                  id="name"
                  class="form-control shadow-1"
                  type="text"
                  v-model="name"
                  v-on:keyup="formMessage = null"
                  placeholder="クーポン名を入力してください。"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-12 col-xl-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">利用制限</p>
                <div class="col-sm-9">
                  <b-form-radio-group
                    v-model="type"
                    name="type"
                  >
                    <b-form-radio value="1">1回のみ</b-form-radio>
                    <b-form-radio value="2">複数回可能</b-form-radio>
                    <b-form-radio value="3">期間内何度でも可能</b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">クーポンコード</p>
                <input
                  id="code"
                  class="form-control shadow-1"
                  type="code"
                  v-model="code"
                  v-on:keyup="formMessage = null"
                  placeholder="クーポンコードを入力して下さい"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">利用可能回数</p>
                <input
                  id="available_count"
                  class="form-control shadow-1"
                  type="available_count"
                  v-model="available_count"
                  v-on:keyup="formMessage = null"
                  placeholder="利用可能回数を入力して下さい"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">割引金額</p>
                <input
                  id="amount"
                  class="form-control shadow-1"
                  type="amount"
                  v-model="amount"
                  v-on:keyup="formMessage = null"
                  placeholder="割引金額を入力して下さい"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-12">
            <div class="mt-4">
            <p class="m-0 font-weight-bold">有効期限(開始日)</p>
            <v-date-picker
                v-model="start_at"
                :masks="{ L: 'YYYY-MM-DD' }"
                locale="ja"
                mode="datetime"
            >
                <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                    <input
                    :value="inputValue"
                    v-on:click="togglePopover"
                    class="form-control datepicker-input shadow-1"
                    placeholder="YYYY-MM-DD HH:mm:ss"
                    required
                    />
                </div>
                </template>
            </v-date-picker>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-12">
            <div class="mt-4">
            <p class="m-0 font-weight-bold">有効期限(終了日)</p>
            <v-date-picker
                v-model="end_at"
                :masks="{ L: 'YYYY-MM-DD' }"
                locale="ja"
                mode="datetime"
            >
                <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                    <input
                    :value="inputValue"
                    v-on:click="togglePopover"
                    class="form-control datepicker-input shadow-1"
                    placeholder="YYYY-MM-DD HH:mm:ss"
                    />
                </div>
                </template>
            </v-date-picker>
            </div>
        </div>
        <div class="col-12">
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mt-5">
          <div class="row mt-5">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <router-link :to="{ name: 'adminRoute-coupon-list' }">
                <button
                  class="btn btn-ds light-grey white-text mb-2"
                  v-waves.light
                >
                  キャンセル
                </button>
              </router-link>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <button
                class="btn btn-ds dark-blue white-text mb-2"
                type="submit"
                v-waves.light
              >
                更新
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <b-modal
      id="coupon-detail-update-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          新規クーポンが作成されました。
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <router-link :to="{ name: 'adminRoute-coupon-list' }">
              <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2"
                type="button"
                v-waves.light
              >
                OK
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import { adm_stagingService_CreateCouponDetail } from '../../services/admin/staging';
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },
  components: {
    'v-date-picker': DatePicker,
  },
  data() {
    return {
      id: null,
      name: null,
      type: null,
      code: null,
      available_count: null,
      amount: null,
      start_at: null,
      end_at: null,
      rules: {
        length: false,
        uppercase: false,
        lowercase: false,
      },
      formMessage: null,
    };
  },
  watch: {
    rules: {
      handler(newVal, oldVal) {
        if (Object.values(newVal).includes(false)) {
          this.passwordTooltip = true;
          this.disabledPasswordTooltip = false;
        } else {
          this.disabledPasswordTooltip = true;
          this.passwordTooltip = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    formatDate(date) {
      if (date == null) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },

    validateCreateCoupon(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      if (_this.name == '') {
        _this.formMessage = 'Name is required.';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
        return;
      }
      if (_this.type == '') {
        _this.formMessage = 'Type is required.';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
        return;
      }
      if (_this.code == '') {
        _this.formMessage = 'Code is required.';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
        return;
      }
      if (_this.amount == '') {
        _this.formMessage = 'Amount is required.';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
        return;
      }
      _this.createCoupon();
    },

    createCoupon() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      let couponDetail = {
        name: _this.name,
        type: _this.type,
        code: _this.code,
        available_count: _this.available_count,
        amount: _this.amount,
        start_at: _this.formatDate(_this.start_at),
        end_at: _this.formatDate(_this.end_at),
        token: accessToken,
      };
      adm_stagingService_CreateCouponDetail(couponDetail)
        .then((response) => {
          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
          console.log(response);
          if (!response.data.is_succeeded) {
            _this.formMessage =
              'クーポン情報の登録中にエラーが起こりました。再度お試しください。';
            let messages = response.data.message;
            for (let key in messages) {
              _this.formMessage += key + ' is ' + messages[key] + '.';
            }
            return;      
          }
          setTimeout(() => {
            _this.$bvModal.show('coupon-detail-update-success-modal');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage =
            'クーポン情報の登録中にエラーが起こりました。再度お試しください。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 1500);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
</style>